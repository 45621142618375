<template>
  <div class="content">
    <div class="zoomin delay-000"><Logo size="small"></Logo></div>
    <h1 class="zoomin delay-000">Wie wird's gemacht?</h1>
    <p class="zoomin delay-100">
      Die kreativen mARTa Taschen sind nicht nur bei Privatpersonen beliebt,
      sondern eignen sich auch als Kundengeschenke. Schau dir doch
      <router-link to="/taschen">die Fotos</router-link> an und lass dich von
      der Vielfältigkeit der mARTa Taschen überzeugen.
    </p>
    <p class="zoomin delay-200">
      Kannst du dir vorstellen, dass die Wahl deiner nächsten Tasche auf eine
      mARTa fällt? Das freut mich sehr!
    </p>
  </div>

  <div class="steps">
    <HowItsDoneStepDisplay
      number="1"
      icon="envelopes-bulk"
      title="Anfrage schicken"
      description="Schicke mir eine Anfrage per E-Mail mit deinen Wünschen!"
      color="var(--color4)"
      class="slidein-left delay-200"
    ></HowItsDoneStepDisplay>

    <HowItsDoneStepDisplay
      number="2"
      icon="wand-magic-sparkles"
      title="Personalisieren"
      description="Gemeinsam entwerfen wir deine persönliche mARTa Tasche!"
      color="var(--color1)"
      class="slidein-top delay-400"
    ></HowItsDoneStepDisplay>

    <HowItsDoneStepDisplay
      number="3"
      icon="scissors"
      title="Anfertigen"
      description="Ich fertige deine gewünschte Tasche für dich an!"
      color="var(--color2)"
      class="slidein-bottom delay-600"
    ></HowItsDoneStepDisplay>

    <HowItsDoneStepDisplay
      number="4"
      icon="gifts"
      title="Geniessen"
      description="Geniesse die neue mARTa Tasche mit deiner persönlichen Note!"
      color="var(--color3)"
      class="slidein-right delay-800"
    ></HowItsDoneStepDisplay>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import HowItsDoneStepDisplay from "@/components/HowItsDoneStepDisplay.vue";
import Logo from "@/components/Logo.vue";

export default defineComponent({
  name: "HowItsDone",
  components: { HowItsDoneStepDisplay, Logo },
  setup() {
    return {};
  },
});
</script>

<style scoped lang="scss">
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 30% 20px 30%;
  text-align: center;

  @media (max-width: 768px) {
    padding: 30px 5%;
  }

  h1 {
    margin-bottom: 0;
  }

  a {
    margin: 50px 0;
    color: $secondary;
    text-decoration: underline;
  }
}

.steps {
  display: flex;
  justify-content: center;
  padding: 0 10%;

  column-gap: 40px;

  > * {
    width: min(22%, 220px);
    text-align: center;
  }

  @media (max-width: 1200px) {
    flex-wrap: wrap;
    row-gap: 20px;
    margin-bottom: 40px;

    > * {
      width: min(33%, 320px);
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    row-gap: 20px;

    > * {
      width: 80%;
    }
  }
}
</style>
