import { SociableKitChildren as SociableKitPostChildren } from "./SociableKitChildren";

export class SociableKitInstagramPost {
  code!: string;
  owner_id!: string | null;
  owner_profile_pic_url!: string | null;
  owner_username!: string;
  link!: string;
  post_link!: string;
  full_pic_src!: string;
  pic_src!: string;
  children!: SociableKitPostChildren[];
  ago_value!: string;
  official_api!: number;
  image_url!: string;
  thumbnail_url!: string;
  pic_text_raw!: string;
  pic_text!: string;
  pic_like_count_formatted!: number;
  pic_comment_count_formatted!: number;
  pic_type!: string;
  carousel_media!: string;
  carousel_pics!: string;
  video_url!: string;
  date_time_posted!: string;
  thumbnail_sk_img!: string;
}
