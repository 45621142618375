<template>
  <div class="content">
    <div class="logo">
      <Logo class="zoomin"></Logo>
    </div>
    <span class="tagline zoomin delay-100">die Tasche mit der persönlichen Note!</span>
    <p class="zoomin delay-100">
      Aus Recycling-Blachen nähe ich jede Tasche mit persönlicher Note, welche
      durch ihre witzige, individuelle und originelle Art besticht und jedem
      Besitzer Freude bereitet.
    </p>
    <p class="mb0 zoomin delay-200">bereits ab</p>
    <span class="pricetag zoomin delay-200">CHF 39.-</span>
    <button class="primary zoomin delay-300" @click="decryptMailTo()">Jetzt anfragen</button>

    <nav>
      <NavItemDisplay
        icon="bag-shopping"
        :color="'color1'"
        text="Taschen"
        link="/taschen"
        class="zoomin delay-400"
      />
      <NavItemDisplay
        icon="clipboard-question"
        :color="'color2'"
        text="Wie wird's gemacht?"
        link="/wie-wirds-gemacht"
        class="zoomin delay-500"
      />
      <NavItemDisplay
        icon="scroll"
        :color="'color3'"
        text="AGB / Impressum"
        link="/agb"
        class="zoomin delay-600"
      />
    </nav>
  </div>
</template>

<script lang="ts">
import Logo from "@/components/Logo.vue";
import NavItemDisplay from "@/components/NavItemDisplay.vue";
import stringMixins from "@/mixins/stringMixins";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Home",
  components: {
    Logo,
    NavItemDisplay,
  },
  setup() {
    const decryptMailTo = (): void => {
      const address = atob(stringMixins.encodedEmailAddress);
      window.location.href = "mailto:" + address;
    };

    return {
      decryptMailTo,
    };
  },
});
</script>

<style scoped lang="scss">
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 30%;
  text-align: center;

  @media (max-width: 768px) {
    padding: 30px 5%;
    margin-bottom: 40px;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;

    img {
      fill: $primary;
    }
  }

  .tagline {
    font-size: 1.5rem;
  }

  .pricetag {
    font-size: 4rem;
    font-weight: bold;
    color: $secondary;

    @media (max-width: 768px) {
      font-size: 3rem;
    }
  }

  button {
    margin: 1em 0;
  }

  nav {
    margin-top: 60px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    column-gap: 40px;

    @media (max-width: 1200px) {
      justify-content: center;
      column-gap: 40px;
    }

    @media (max-width: 768px) {
      flex-wrap: wrap;
      row-gap: 80px;
      a {
        width: 100%;
      }
    }
  }
}
</style>
