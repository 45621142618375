<template>
  <div class="content">
    <div><Logo size="small"></Logo></div>

    <!-- AGB -->

    <h2>Allgemeine Geschäftsbedingungen</h2>
    <p>
      Eine Bestellung ist rechtlich verbindlich und gilt als rechtsverbindlicher
      Kaufvertrag.
    </p>

    <p><em>marta-taschen.ch</em> behält sich jederzeit vor eine Bestellung zu stornieren.</p>

    <h3>Angebot</h3>
    <p>
      Die Taschen entstehen nach einem eigenem Muster und dürfen ohne Erlaubnis
      von <em>marta-taschen.ch</em> nicht kopiert werden, Zudem werden die Farben aller
      Produkte je nach Bildschirmeinstellung und Auflösung leicht anders
      dargestellt.
    </p>

    <h3>Bestellung</h3>
    <p>
      Eine Bestellung bei <em>marta-taschen.ch</em> ist ein rechtsverbindlicher Kaufvertrag.
      Bestellungen können via Webshop, telefonisch, per Email, über Instagram oder über Facebook
      erfolgen. <em>marta-taschen.ch</em> behält sich die Verfügbarkeit einzelner Artikel vor.
      Sollte ein Artikel nicht lieferbar sein, werden sie umgehend kontaktiert.
      Mit der Bestellung anerkennt der Kunde die vorliegenden AGB.
    </p>

    <h3>Preise</h3>
    <p>
      Alle Preise verstehen sich inkl. Mehrwertsteuer, die Portokosten werden
      separat ausgewiesen. Irrtum bei Beschreibung, Bilder und Preis sind
      vorbehalten. Sämtliche Preise sind in CHF ausgewiesen.
    </p>

    <h3>Versandkosten</h3>
    <p>
      Die Versand und Verpackung werden separat verrechnet. Pauschal werden Fr.
      15.- in Rechnung gestellt. Versandkosten Ausland abweichend. Bitte
      beachten Sie, dass der Versand ins Ausland Basis unversichert ist. Für
      versicherten Auslandversand fallen Zusatzkosten in Höhe von 19.- an. Wenn
      sie versicherten Auslandversand wünschen kontaktieren sie mich vor der
      Bestellung. Allfällig anfallende Zollkosten gehen zu Lasten des
      Empfängers.
    </p>

    <h3>Bezahlung</h3>
    <p>
      Versand gegen Vorauskasse Zahlungsmöglichkeiten: 
      <ul>
        <li>Überweisung</li>
        <li>Barzahlung bei Abholung</li>
        <li>Twint</li>
      </ul>
    </p>

    <h3>Mahngebühren</h3>
    <p>
      Sollte bestellte Ware nicht innert 20 Kalendertagen bezahlt werden, erhält
      der Kunde eine Mahnung. Sollte nach weiteren 7 Kalendertagen keine Zahlung
      bei <em>marta-taschen.ch</em> eingehen wird eine 2. Mahnung versendet welche dem Kunden
      mit 10.- belastet wird. Nach weiteren 7 Kalendertagen erfolgt die 3.
      Mahnung die dem Kunden mit 30.- belastet wird. 20 Tage nach der Zustellung
      der 3. Mahnung wird ein Inkassobüro beigezogen. Sämtliche anfallende
      Kosten sind durch den Käufer zu zahlen.
    </p>

    <p>
      Werden vereinbarte Ratenzahlungen nicht eingehalten, gelten dieselben
      Bestimmungen.
    </p>

    <h3>Eigentumsvorbehalt</h3>
    <p>
      Bis zur vollständigen Bezahlung bleibt die Ware Eigentum von <em>marta-taschen.ch</em>.
    </p>

    <h3>Liefergebiet, -fristen und -bedingungen</h3>
    <p>
      <em>marta-taschen.ch</em> liefert innerhalb der Schweiz, sowie weltweit. Die Lieferfrist
      ist bei den Artikeln angegeben und läuft ab Zahlungseingang. Bei nicht
      versichertem Versand geht das Risiko eines Verlustes auf den Käufer über.
      Versicherung (Einschreiben) kann für 2.- dazu geordert werden. Sollten sie
      10 Tage nach Zahlung noch keine Lieferung erhalten haben melden Sie sich
      bitte. Abholungen der Ware im Lager/Laden von <em>marta-taschen.ch</em> müssen spätestens
      2 Tage im Voraus angemeldet werden.
    </p>

    <h3>Rückgaberecht / Rücksendung</h3>
    <p>
      Sie haben das Recht, den Kaufvertrag innerhalb von 5 Tagen nach
      Bestelleingang zu widerrufen. Erhaltene Ware können Sie auf eigene Kosten
      zurückzusenden. Dies gilt nicht für speziell auf Wunsch gefertigte Artikel
      diese können NICHT storniert werden. Die Ware muss in einwandfreiem und
      unversehrtem Zustand sein und Originalverpackt bei uns eingehen. Ablehnung
      von Kaufrücktritten behalten wir uns vor. Bereits bezahlte Beträge werden,
      unter Abzug der Lieferkosten, in Form eines Gutscheins zurückerstattet.
      Eine Rücküberweisung aufs Konto erfolgt nur in Ausnahmefällen und unter
      abzug von 15% Bearbeitungsgebühr.
    </p>

    <h3>Storno</h3>
    <p>
      Käufe können innerhalt von 24 Stunden storniert werden. Für Stornos 2-14
      Tage nach Kauf ist eine Stornogebühr von 30% fällig, für Stornierungen von
      mehr als 14 Tagen nach dem Kauf eine Stornogebühr von 50% des
      Einkaufsbetrags. Bei Bestellung gegen Vorauskasse wird die Stornogebühr in
      Rechnung gestellt. Die Zahlungsfristen und Mahngebühren gelten auch für
      die Stornogbühren. Bereits bezahlte Beträge werden in Form eines
      Gutscheins zurückerstattet. Wird auf eine Überweisung auf ein Konto
      beharrt werden 15% der Restsumme als Aufwandsentschädigung abgezogen.
    </p>

    <h3>Mängel/Gewährleistung und Haftung</h3>
    <p>
      Beanstandungen wegen Lieferumfang, Sachmängel oder Falschlieferung müssen
      unverzüglich, spätestens jedoch innerhalb von 5 Tagen nach Erhalt der
      Waren, per e-Mail oder telefonisch geltend gemacht werden. Umtausch oder
      Rücknahme der Ware erfolgt nur bei berechtigter Mängelrüge oder nach
      persönlicher Vereinbarung und innerhalb der erwähnten Frist, ansonsten
      erlischt der Garantieanspruch. Soweit es die gesetzlichen Bestimmungen
      erlauben, ist eine Haftung für den Schadenersatz, aus welchem Rechtsgrund
      auch immer, ausgeschlossen. Insbesondere haftet <em>marta-taschen.ch</em> nicht für
      indirekten Schaden oder Mängelfolgeschäden.
    </p>

    <h3>Übergang von Nutzen und Gefahr</h3>
    <p>
      Nutzen und Gefahr der Kaufgegenstände gehen mit Versand der Lieferung ab
      Lager an den Käufer über.
    </p>

    <h3>Gerichtsstand</h3>
    <p>
      Die vorliegenden AGB und die Verträge, welche aufgrund dieser AGB
      abgeschlossen werden, unterliegen Schweizerischem Recht unter Ausschluss
      des UN-Kaufrechts (CISG). Der Gerichtsstand ist Interlaken.
    </p>

    <h3>Schlussbestimmungen</h3>
    <p>
      Sollte sich eine Bestimmung dieser AGB als ungültig oder unwirksam
      erweisen, berührt dies den Bestand der übrigen Bestimmungen nicht.
      <em>marta-taschen.ch</em> behält sich die jederzeitige Anpassung/Änderung dieser AGB vor.
    </p>

    <!-- Impressum -->

    <h2 class="mt">Impressum</h2>

      <p class="m0">Frau</p>
      <p class="m0">Marlise Wyss</p>
      <p class="m0">Trübenbachweg 16</p>
      <p class="m0">3706 Leissigen</p>
      <p><a @click="decryptMailTo()">{{ decryptMailDisplay() }}</a></p>

    <p>Verantwortlich für die Webseite: <em>marta-taschen.ch</em></p>

    <p>
      Mit der Nutzung dieser Webseite erklären Sie sich mit den nachfolgenden
      Bedingungen einverstanden.
    </p>

    <p>
      Alle Angebote sind unverbindlich. Die <em>marta-taschen.ch</em> behält sich vor, Teile
      der Seiten oder das gesamte Angebot ohne gesonderte Ankündigungen zu
      verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise
      oder endgültig einzustellen.
    </p>

    <h3>Datenschutzerklärung</h3>

    <p>
      Der Schutz Ihrer Privatsphäre ist uns ein Anliegen. Bitte beachten Sie die
      folgenden Punkte unserer Datenschutzerklärung.
    </p>

    <p>
      Die Datenschutzerklärung gilt für alle Webseiten von <em>marta-taschen.ch</em>. Für
      <em>marta-taschen.ch</em>-fremde Internetseiten, zu welchen von den <em>marta-taschen.ch</em> Webseiten
      ein Link besteht, gewähren wir nicht die Einhaltung der
      Datenschutzbestimmungen.
    </p>

    <h3>Cookies allgemein</h3>

    <p>
      Diese Website verwendet keine Cookies.
    </p>


    <h3>Cookies & Google Analytics</h3>

    <p>
      Diese Website verwendet keine Analytics.
    </p>

    <h3>Haftungsausschluss</h3>

    <p>
      Die <em>marta-taschen.ch</em> übernimmt keine Gewähr hinsichtlich der inhaltlichen
      Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und Vollständigkeit
      der Informationen.
    </p>

    <p>
      Haftungsansprüche gegen die <em>marta-taschen.ch</em> wegen Schäden materieller oder
      immaterieller Art, welche aus dem Zugriff oder der Nutzung bzw.
      Nichtnutzung der veröffentlichten Informationen, durch Missbrauch der
      Verbindung oder durch technische Störungen entstanden sind, werden
      ausgeschlossen.
    </p>

    <p>
      Der Besuch der <em>marta-taschen.ch</em> Webseiten, die Benützung der dort enthaltenen
      Informationen sowie der Gebrauch von dort aufgeführten Angeboten erfolgt
      auf Ihre Gefahr und Verantwortung.
    </p>

    <p>
      Ausgeschlossen wird die Haftung auch für entgangenen Gewinn, für
      Fehlleistungen des Internets aller Art (z.B. Betriebsunterbrechung,
      Funktionsstörungen, Viren, schädliche Komponenten, terroristische
      Handlungen... etc.), Missbrauch durch Dritte (Einsehen von Daten, Kopien
      usw.) sowie Verlust von Programmen oder sonstigen Daten in Ihren
      Informationssystemen. Dies gilt auch dann, wenn auf den <em>marta-taschen.ch</em>
      Webseiten gegebenenfalls auf die Möglichkeit solcher Schäden hingewiesen
      wird.
    </p>

    <h3>Haftung für Links</h3>

    <p>
      Manche Links auf den <em>marta-taschen.ch</em> Websites führen zu Websites Dritter. Diese
      stehen nicht mehr unter dem Einflussbereich der <em>marta-taschen.ch</em> .
    </p>

    <p>
      Für die Richtigkeit, Vollständigkeit und Rechtmässigkeit der dort
      enthaltenen Inhalte und Verlinkung mit weiteren Websites sowie für
      allfällige dort enthaltene Angebote, Produkte und (Dienst-) Leistungen
      übernimmt <em>marta-taschen.ch</em> deshalb keinerlei Verantwortung.
    </p>

    <p>
      Dies gilt auch dann, wenn diese Webseiten das <em>marta-taschen.ch</em> Logo oder eine
      andere geschützte Bezeichnung enthalten. Der Zugriff und die Nutzung
      verlinkter Websites erfolgt auf eigene Gefahr des Nutzers.
    </p>

    <h3>Anwendbares Recht und Gerichtsstand</h3>

    <p>
      Für sämtliche allfällige Streitigkeiten zwischen Ihnen als Besucher und
      Nutzer der <em>marta-taschen.ch</em> Webseiten und <em>marta-taschen.ch</em>, welche aus dem Betrieb
      bzw. Besuch der <em>marta-taschen.ch</em> Webseiten herrühren, sind ausschliesslich die
      Gerichte am Sitz von <em>marta-taschen.ch</em> in Thun zuständig. Anwendbar ist
      ausschliesslich Schweizer Recht.
    </p>

    <h3>Urheberrechte</h3>

    <p>
      Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder
      anderen Dateien auf der Website gehören ausschliesslich der <em>marta-taschen.ch</em>
      oder speziell genannten Rechtsinhabern. Für die Reproduktion jeglicher
      Elemente ist die schriftliche Zustimmung der Urheberrechtsträger im Voraus
      einzuholen.
    </p>

    <h3>Unsere Bildquellen</h3>
    <p>
      <ul>
        <li>mART20.ch</li>
      </ul>
    </p>

    <p>&nbsp;</p>
    <p>Letzte Aktualisierung: Februar 2024</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Logo from "@/components/Logo.vue";
import stringMixins from "@/mixins/stringMixins";

export default defineComponent({
  name: "Agb",
  setup() {
    const decryptMailTo = (): void => {
      const address = atob(stringMixins.encodedEmailAddress);
      window.location.href = "mailto:" + address;
    };
    const decryptMailDisplay = (): string => {
      const address = atob(stringMixins.encodedEmailAddress);
      return address;
    };

    return {
      decryptMailTo,
      decryptMailDisplay,
    };
  },
  components: { Logo },
});
</script>

<style scoped lang="scss">


.content {
  display: flex;
  flex-direction: column;
  padding: 50px 10%;
  text-align: left;

  @media (max-width: 768px) {
    padding: 30px 5%;
  }

  > div {
    text-align: center;

    a {
      margin: 50px 0;
    }
  }

  .mt {
    margin-top: 120px;
  }

  h3 {
    margin-bottom: 5px;
  }

  p {
    margin-top: 0;
  }

  em {
    font-weight: bold;
  }

  a {
    text-decoration: underline;
    color: $secondary;
  }
}
</style>
