import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4316eaf6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "step",
  ref: "stepRef"
}
const _hoisted_2 = { class: "icon" }
const _hoisted_3 = { class: "text" }
const _hoisted_4 = { class: "number" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_font_awesome_icon, {
        icon: ['fas', _ctx.icon]
      }, null, 8, ["icon"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.number), 1),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.title), 1),
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.description), 1)
    ])
  ], 512))
}