<template>
  <div class="step" ref="stepRef">
    <div class="icon">
      <font-awesome-icon :icon="['fas', icon]" />
    </div>
    <div class="text">
      <span class="number">{{ number }}</span>
      <span class="title">{{ title }}</span>
      <span class="description">{{ description }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watchEffect } from "vue";

export default defineComponent({
  name: "HowItsDoneStepDisplay",
  props: {
    number: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const stepRef = ref<InstanceType<typeof HTMLElement>>();

    watchEffect(() => {
      if (stepRef.value) {
        stepRef.value.style.setProperty("--color", props.color);
      }
    });

    return {
      stepRef,
    };
  },
});
</script>

<style scoped lang="scss">
.step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $textSecondary;

  @media (max-width: 768px) {
    row-gap: 5px;
  }

  &:hover {
    .icon {
      transform: scale(1.1);
    }
    .text {
      padding-top: 45px;
      padding-bottom: 0px;
      .number {
        background-color: transparent;
        color: $textSecondary;
      }
    }
  }

  .icon {
    transition: transform 200ms ease;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 3px solid;
    width: 100px;
    height: 100px;
    font-size: 3.5rem;
    background: $textSecondary;
    z-index: 1;
    color: var(--color);
    border-color: var(--color);
  }

  .text {
    transition: padding 200ms ease, transform 200ms ease;
    width: calc(100% - 40px);

    position: relative;
    margin-top: -30px;
    border-radius: 25px;
    padding: 35px 20px 10px 20px;
    min-width: 120px;
    display: inline-flex;
    flex-direction: column;
    min-height: 180px;
    background-color: var(--color);

    .title {
      text-transform: uppercase;
      font-weight: bold;
      margin: 10px 0 20px 0;
    }

    .number {
      transition: background-color 200ms ease, color 200ms ease;

      position: absolute;
      top: 10px;
      left: 10px;
      color: $textPrimary;
      font-weight: bold;
      border-radius: 50%;
      background-color: $textSecondary;
      width: 25px;
      height: 25px;
      color: var(--color);
    }

    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
}
</style>
