import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/scss/main.scss";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import * as icons from "@fortawesome/free-solid-svg-icons";

/* add icons to the library */
library.add(icons.faUserSecret);
library.add(icons.faScissors);
library.add(icons.faBagShopping);
library.add(icons.faMap);
library.add(icons.faBars);
library.add(icons.faEnvelope);
library.add(icons.faHouse);
library.add(icons.faAddressCard);
library.add(icons.faAt);
library.add(icons.faCartShopping);
library.add(icons.faBagShopping);
library.add(icons.faClipboardQuestion);
library.add(icons.faScroll);
library.add(icons.faEnvelopesBulk);
library.add(icons.faWandMagicSparkles);
library.add(icons.faGifts);

createApp(App)
  .use(router)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
