import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "background",
  ref: "backgroundRef"
}
const _hoisted_2 = { id: "copyright" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, null, 512),
    _createVNode(_component_router_view),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("small", null, "© " + _toDisplayString(new Date().getFullYear()) + " - marta-taschen.ch", 1)
    ])
  ], 64))
}