<template>
  <div class="content">
    <div><Logo size="small"></Logo></div>
    <h2>bisherige Werke</h2>
    <div>
      <a
        href="https://www.instagram.com/marta_taschen/"
        target="blank"
        class="instagram-link"
      >
        <img src="@/assets/img/instagram.svg" height="24" /> marta_taschen
      </a>
    </div>
    <div class="posts">
      <template v-if="isLoading">
        <template v-for="i in 10">
          <div class="skeleton" :class="['zoomin', getDelayForPost(i)]">
            <span class="loader"></span>
          </div>
        </template>
      </template>
      <template v-else>
        <template v-for="(post, i) of posts" :key="post.code">
          <PostDisplay
            :post="post"
            :modal="modalRef"
            :class="['zoomin', getDelayForPost(i)]"
          ></PostDisplay>
        </template>
      </template>
    </div>
  </div>
  <Modal ref="modalRef"></Modal>
</template>

<script lang="ts">
import { SociableKitInstagramPost } from "@/types/SociableKitInstagramPost";
import { InstagramApiService } from "@/services/InstagramApiService";
import { defineComponent, onMounted, ref } from "vue";
import PostDisplay from "@/components/PostDisplay.vue";
import Logo from "@/components/Logo.vue";
import Modal from "@/components/Modal.vue";

export default defineComponent({
  name: "Posts",
  components: { PostDisplay, Logo, Modal },
  setup() {
    const modalRef = ref();
    const posts = ref<InstanceType<typeof SociableKitInstagramPost>[]>();
    const apiService = new InstagramApiService();

    // posts with that code will not be shown.
    const blacklistedCodes = [
      "Cl_cEHBoi7l" /* Silberkurs */,
      "CldkVScKu66" /* Adventskranz */,
      "Cw_QsUgqPqj" /* Atelier Handweben Bönigen */,
      "CqkkMfkKbvE" /* Kleine Portmonnaie-Taschen */,
    ];

    const isLoading = ref(false);

    const getDelayForPost = (i: number) => {
      return `delay-${Math.max(Math.min(i, 10), 0)}00`;
    };

    onMounted(() => {
      isLoading.value = true;
      apiService
        .getPosts()
        .then((result: SociableKitInstagramPost[]) => {
          // filter with blacklist and ensure type.
          posts.value = result
            .filter((p) => blacklistedCodes.every((b) => b !== p.code))
            .map((p) => Object.assign(new SociableKitInstagramPost(), p));
        })
        .finally(() => {
          isLoading.value = false;
        });
    });

    return {
      posts,
      modalRef,
      isLoading,
      getDelayForPost,
    };
  },
});
</script>

<style scoped lang="scss">
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 50px;

  @media (max-width: 768px) {
    padding: 30px 5%;
  }
}

.instagram-link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  margin-bottom: 20px;
}

.posts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 20px;
  row-gap: 20px;

  @media (max-width: 768px) {
    justify-content: space-around;
    > * {
      width: 90%;
      height: unset !important;
    }
  }

  > div {
    height: 300px;
  }

  .skeleton {
    border-radius: 8px;
    background: grey;
    opacity: 0.5;
    aspect-ratio: 1 / 1;
    position: relative;

    .loader {
      top: calc(50% - 24px);
      left: calc(50% - 24px);
      position: absolute;
      width: 48px;
      height: 48px;
      border: 5px solid #fff;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }
  }
}
</style>
