<template>
  <div id="background" ref="backgroundRef"></div>
  <router-view />
  <div id="copyright">
    <small>&copy; {{ new Date().getFullYear() }} - marta-taschen.ch</small>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue";

export default defineComponent({
  name: "App",
  components: {},
  setup() {
    const backgroundRef = ref<InstanceType<typeof HTMLElement>>();

    const handleScroll = (event: Event) => {
      if (backgroundRef.value) {
        const scrollRatio = -window.scrollY / 10;
        backgroundRef.value.style.backgroundPositionY = `${scrollRatio}px`;
      }
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    return {
      backgroundRef,
    };
  },
});
</script>

<style lang="scss">
h1,
h2 {
  text-transform: uppercase;
}

h1 {
  font-size: 4rem;
}
h2 {
  font-size: 2rem;
}
body {
  font-size: 1rem;
}

button {
  transition: outline-color 200ms ease, transform 200ms ease;

  border-radius: 40px;
  min-width: 100px;
  padding: 15px 40px;
  border: none;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  outline-offset: 3px;
  outline: 3px solid transparent;

  &.primary {
    background: $primary;
    color: $textSecondary;

    &:hover {
      transform: scale(1.05);
      outline-color: $secondary;
    }

    &:active {
      background: $secondary;
    }
  }
}

#copyright {
  position: relative;
  padding: 10px;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1rem;
  }
  body {
    font-size: 0.8rem;
  }
}
</style>
