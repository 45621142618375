export default {
  toDate(text: string): string {
    const monthsNames = [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember",
    ];

    const date = new Date(text);
    return `${monthsNames[date.getMonth()]} ${date.getFullYear()}`;
  },

  encodedEmailAddress: "bWFydGEyMEBibHVld2luLmNo",
};
