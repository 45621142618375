import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouterScrollBehavior,
} from "vue-router";
import Home from "../views/Home.vue";
import Posts from "../views/Posts.vue";
import HowItsDone from "../views/HowItsDone.vue";
import Agb from "../views/Agb.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/taschen",
    name: "Posts",
    component: Posts,
  },
  {
    path: "/wie-wirds-gemacht",
    name: "HowItsDone",
    component: HowItsDone,
  },
  {
    path: "/agb",
    name: "Agb",
    component: Agb,
  },
];

// return to top of page when navigating.
const scrollBehavior: RouterScrollBehavior = (
  to: any,
  from: any,
  savedPosition: any
) => {
  if (savedPosition) return savedPosition;
  if (to.hash) return { selector: to.hash };

  return { left: 0, top: 0 };
};

// create router component.
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: scrollBehavior,
});
export default router;
