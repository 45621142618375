import { SociableKitInstagramPost } from "@/types/SociableKitInstagramPost";
import { SociableKitResponse } from "@/types/SociableKitResponse";
import Axios from "axios";

export class InstagramApiService {
  private BASE_URL = "https://data.accentapi.com/feed";

  /* FEED_ID retrieved from "data-embed-id" of SociableKIT */
  private FEED_ID = "25367728";

  private API_URL = `${this.BASE_URL}/${this.FEED_ID}.json`;

  async getPosts(): Promise<SociableKitInstagramPost[]> {
    return Axios.get<SociableKitResponse>(this.API_URL).then(
      (response) => response.data.posts
    );
  }
}
