<template>
  <div class="modal" :class="{ open: isOpen }" @click="closeModal()">
    <template v-if="post">
      <a class="close-icon" @click="closeModal()">X</a>
      <img :src="post.image_url" :title="post.pic_text" />
      <div class="info">
        <small>{{ toDate(post.date_time_posted) }}</small>
        <span><a href="https://www.instagram.com/marta_taschen/" target="blank">marta_taschen auf Instagram</a></span>
        <!-- description is currently not working by the API -->
        <!-- <span>{{ getDescriptionWithoutTags(post.pic_text) }}</span>
        <span class="hashtags">
          <template v-for="tag of getTagsFromDescription(post.pic_text)">
            <a
              :href="'https://www.instagram.com/explore/tags/' + tag"
              target="blank"
            >
              {{ "#" + tag }}
            </a>
            &nbsp;
          </template>
        </span> -->
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { SociableKitInstagramPost } from "@/types/SociableKitInstagramPost";
import stringMixins from "@/mixins/stringMixins";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Modal",
  setup() {
    const post = ref<InstanceType<typeof SociableKitInstagramPost>>();
    const isOpen = ref(false);

    const closeModal = () => {
      isOpen.value = false;
    };
    const openModal = (p: SociableKitInstagramPost) => {
      post.value = p;
      isOpen.value = true;
    };

    const toDate = stringMixins.toDate;

    const getTagsFromDescription = (text: string): string[] => {
      return text
        .split("#")
        .slice(1)
        .filter((a) => a);
    };
    const getDescriptionWithoutTags = (text: string): string => {
      return text.split("#")[0];
    };

    return {
      closeModal,
      openModal,
      toDate,
      getTagsFromDescription,
      getDescriptionWithoutTags,
      post,
      isOpen,
    };
  },
});
</script>

<style scoped lang="scss">
.modal {
  position: fixed;
  backdrop-filter: blur(2px);
  background: rgba(black, 0.9);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;

  animation: fadein 150ms ease;
  display: none;
  opacity: 0;

  &.open {
    display: block;
    opacity: 1;
  }

  .close-icon {
    position: absolute;
    top: 50px;
    right: 50px;
    color: $textSecondary;
    font-weight: bold;
    font-size: 3rem;
    line-height: 2rem;
    cursor: pointer;

    @media (max-width: 768px) {
      top: 10px;
      right: 10px;
      font-size: 2rem;
    }
  }

  img {
    position: absolute;
    top: 50px;
    max-height: calc(100% - 240px);
    max-width: calc(100% - 240px);
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 768px) {
      top: 60px;
      left: 2%;
      width: 96%;
      max-width: 96%;
      transform: none;
    }
  }

  .info {
    position: absolute;
    bottom: 0;
    text-align: center;
    padding: 20px;
    font-size: 2rem;
    color: $textSecondary;
    background: black;
    width: calc(100% - 40px);
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    flex-direction: column;

    span {
      width: 100%;
    }

    small {
      font-size: 0.8rem;
    }

    .hashtags {
      display: inline-flex;
      justify-content: center;
      flex-wrap: wrap;

      a {
        color: $secondary;
        font-size: 1.3rem;
      }
    }

    @media (max-width: 768px) {
      font-size: 1rem;
      row-gap: 10px;

      small {
        font-size: 0.6rem;
      }

      .hashtags {
        a {
          font-size: 0.8rem;
        }
      }
    }
  }
}
</style>
