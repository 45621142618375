import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f8f72b4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "icon" }
const _hoisted_2 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.link,
    class: _normalizeClass(["nav-item", {
      color1: _ctx.color == 'color1',
      color2: _ctx.color == 'color2',
      color3: _ctx.color == 'color3',
    }])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_font_awesome_icon, {
          icon: ['fas', _ctx.icon]
        }, null, 8, ["icon"])
      ]),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.text), 1)
    ]),
    _: 1
  }, 8, ["to", "class"]))
}