<template>
  <router-link
    :to="link"
    class="nav-item"
    :class="{
      color1: color == 'color1',
      color2: color == 'color2',
      color3: color == 'color3',
    }"
  >
    <div class="icon">
      <font-awesome-icon :icon="['fas', icon]" />
    </div>
    <span class="label">{{ text }}</span>
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "NavItemDisplay",
  props: {
    icon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {};
  },
});
</script>

<style scoped lang="scss">
.nav-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  color: $textSecondary;
  cursor: pointer;
  color: $textSecondary;

  @media (max-width: 768px) {
    row-gap: 5px;
  }

  &:hover {
    &.color1 {
      .label {
        outline-color: $colorVariant1;
      }
    }

    &.color2 {
      .label {
        outline-color: $colorVariant2;
      }
    }

    &.color3 {
      .label {
        outline-color: $colorVariant3;
      }
    }
    .icon {
      transform: scale(0.95);
    }
    .label {
      transform: scale(1.05);
    }
  }

  &.color1 {
    .icon,
    .label {
      background-color: $colorVariant1;
    }
  }

  &.color2 {
    .icon,
    .label {
      background-color: $colorVariant2;
    }
  }

  &.color3 {
    .icon,
    .label {
      background-color: $colorVariant3;
    }
  }

  .icon {
    transition: outline-color 200ms ease, transform 200ms ease;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    font-size: 3.5rem;
  }

  .label {
    transition: outline-color 200ms ease, transform 200ms ease;

    border-radius: 40px;
    padding: 5px 20px;
    min-width: 120px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.9rem;
    outline-offset: 3px;
    outline: 3px solid transparent;

    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
}
</style>
